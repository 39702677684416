.link {
    text-decoration: none;
    font-size: 16px;
    position: relative;
    color: #ffff;
    margin-right: 20px;
}

.link__active::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    background-color: white;
    height: 2px;
}
